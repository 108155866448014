<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import PagesCoreView from "./components/core/View";
export default {
  name: "BaseLayout",
};
</script>
